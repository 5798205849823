import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Layout from "../components/layout"
import SEO from "../components/seo"

const RentThanksPage = ({ data }) => {
  const html = get(
    data,
    "contentfulRentPage.successMessage.childMarkdownRemark.html"
  )

  return (
    <Layout>
      {/* Fixed SEO title */}
      <SEO title="Bedankt" />
      <h1>Bedankt</h1>
      <div className="mb-8 html" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query RentSuccessQuery {
    contentfulRentPage(contentful_id: { eq: "1SDHk8x5KBeLEicrU2E85n" }) {
      successMessage {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default RentThanksPage
